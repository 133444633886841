//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CoursesIntro from '@/components/courses/coursesIntro';
import CoursesFilter from '@/components/courses/coursesFilter';
import createHead from '@/utils/coursesListHead';

export default {
  components: {
    CoursesIntro,
    CoursesFilter,
  },
  layout: 'scaled',
  middleware: ['coursesGuard', 'topics', 'nomenclatureTypes'],

  head() {
    const createHeadFunction = createHead.bind(this);
    return createHeadFunction();
  },
  created() {
    this.$store.commit('courses/setMobileFilterOpened', false);
  },
};
