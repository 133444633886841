export default {
  props: {
    optionsAction: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: [],
    };
  },
  methods: {
    // Функция для заполнения options поля.
    // В компонент можно передать массив items или название экшена в виде строки.
    // Если передать экшен - опшены будут получены с сервера (из стора)
    async fillOptions() {
      const i18n = this.$app?.$i18n?._vm;
      const locale = i18n?.locale;
      const messages = i18n?.messages?.[locale] || {};

      if (this.optionsAction) {
        const result = await this.$store.dispatch(this.optionsAction, {
          vm: this,
          messages,
        });
        this.options = result || [];
      } else {
        this.options = this.items || [];
      }
    },
  },
};
