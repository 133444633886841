//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Price from './-price.vue';
import { getProductPrice } from '@/pages/sale/utils';
import capitalize from 'lodash/capitalize';
import ArrowRightUpIcon from '@/assets/svg/arrow-right-bold-up.svg?inline';
import { mapGetters } from 'vuex';

export default {
  components: {
    Price,
    ArrowRightUpIcon,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    addToBasket: {
      type: Function,
      default: () => {},
    },
    requestCompanyCatalog: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      reactiveData: {},
    };
  },
  computed: {
    ...mapGetters('sale', ['isSaleWithoutPrice', 'isCompanyCatalog']),
    title() {
      return this.data.shortName || this.data.name;
    },
    subtitle() {
      let nomenclatures = this.data.additionalNomenclatures || [];
      nomenclatures = nomenclatures.filter((i) => i.active !== false);
      if (!nomenclatures.length) return '';

      const courseTc = capitalize(this.$tc('course', nomenclatures.length));
      const nomMap = nomenclatures.map((i) => i.shortName || i.name);
      const nomMapString = nomMap.join(', ');
      const free = this.$t('sale.free');
      return `+ ${courseTc} ${nomMapString} ${free}`;
    },
    label() {
      if (this.data.topSale) return 'topSale';
      if (this.data.soldOut) return 'soldOut';
      if (this.data.jobGuarantee) return 'jobGuarantee';
      if (this.data.free) return 'free';
      if (this.data.partnership) return 'partnership';
      return null;
    },
    price() {
      const price = getProductPrice(this.data, this);
      return price;
    },
    computedRequestCatalog() {
      if (
        this.$props.requestCompanyCatalog &&
        this.requestCompanyCatalog.length > 0
      ) {
        return this.$props.requestCompanyCatalog;
      } else {
        return [];
      }
    },
    computedIsAuth() {
      return !!this.$app.user;
    },
  },
  watch: {
    computedRequestCatalog: {
      handler(value) {
        if (value && value.length > 0) {
          if (value.includes(this.reactiveData.id)) {
            this.reactiveData.inCatalogRequest = true;
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.reactiveData = this.$props.data;
    if (process.client) {
      if (
        this.computedRequestCatalog &&
        this.computedRequestCatalog.length > 0
      ) {
        if (this.computedRequestCatalog.includes(this.reactiveData.id)) {
          this.reactiveData.inCatalogRequest = true;
        }
      }
    }
  },
  methods: {
    openDetailDialog() {
      this.$store.dispatch('sale/SHOW_DETAIL_PRODUCT_DIALOG', {
        product: this.data,
        productType: this.type,
      });
    },
    companyCatalogClickHandler() {
      if (this.computedIsAuth) {
        this.reactiveData = { ...this.reactiveData, inCatalogRequest: true };
        this.$emit('sendCompanyCatalogRequest', {
          item: this.$props.data,
          type: this.$props.type,
        });
      } else {
        this.$store.commit('sale/setModal', {
          isVisible: true,
          title: this.$t('sale.companyCatalogDialogContent.title'),
          description: this.$t('sale.companyCatalogDialogContent.content'),
          buttonText: this.$t('index.success-dialog.btn'),
        });
      }
    },
  },
};
