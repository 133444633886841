//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TopSaleCursos from './topSaleCursos';
import TopSaleSale from './topSaleSale.vue';

export default {
  name: 'TopSaleMain',
  components: {
    TopSaleCursos,
    TopSaleSale,
  },
  props: {
    page: {
      type: String,
      required: true,
      default: '',
    },
    basket: {
      type: Array,
      default: () => [],
    },
    addToBasket: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    isComputedTopSaleCoursesAndProfessionsAvailable() {
      if (this.isCursosPage) {
        return (
          this.$store.getters['courses/computedTopSaleCoursesAndProfessions']
            ?.length > 0
        );
      }
      if (this.isSalePage) {
        return (
          this.$store.getters['sale/computedTopSaleCoursesAndProfessions']
            ?.length > 0
        );
      }
      return false;
    },
    isCursosPage() {
      return this.$props.page === 'cursos';
    },
    isSalePage() {
      return this.$props.page === 'sale';
    },
    isLoading() {
      return (
        this.$store.state.sale.isLoadingTopSaleCoursesAndProfessions ||
        this.$store.state.courses.isLoadingTopSaleCoursesAndProfessions
      );
    },
  },
  methods: {
    onCardClick(data) {
      this.$emit('cardClick', data);
    },
  },
};
