//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PageLoader',
  props: {
    black: {
      type: Boolean,
      default: true,
    },
    fullsize: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
  },
};
