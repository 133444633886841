//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import filterMixin from '@/mixins/filter';
import SaleCard from './-saleCard.vue';
import capitalize from 'lodash/capitalize';
import SectionTooltip from '../sectionTooltip';
import { mapGetters } from 'vuex';
import Loader from '@/components/autoscale/loader.vue';

export default {
  components: { Loader, SaleCard, SectionTooltip },
  mixins: [filterMixin],
  model: {
    prop: 'loaded',
    event: 'change',
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    namespace: {
      type: String,
      default: null,
    },

    addToBasket: {
      type: Function,
      default: () => {},
    },
    basket: {
      type: Array,
      default: () => [],
    },
    tooltip: {
      type: Object,
      default: () => ({ title: '', text: '' }),
    },
    requestCompanyCatalog: {
      type: Array,
      default: () => [],
    },
    isTopSaleCoursesOrProfessions: {
      type: Boolean,
      default: false,
    },
    topSaleCoursesAndProfessions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      offset: 0,
      limit: 9,
      lastToLoad: 9,
      loaded: false,
    };
  },
  async fetch() {
    await this.fetchData();
  },
  computed: {
    lastToLoadText() {
      return `${this.$t('courses.cards.more')} ${this.lastToLoad} ${this.$tc(
        this.type,
        this.lastToLoad,
      )}`;
    },
    ...mapGetters('sale', ['isSaleWithoutPrice', 'isCompanyCatalog']),
    // Fetch
    buzySiblings() {
      return (
        this.$store.state.sale.coursesBuzy ||
        this.$store.state.sale.professionsBuzy
      );
    },
    buzy: {
      get() {
        return this.computedGet('sBuzy');
      },
      set(payload) {
        this.computedSet('sBuzy', payload);
      },
    },
    delayedUpdate: {
      get() {
        return this.computedGet('sDelayedUpdate');
      },
      set(payload) {
        this.computedSet('sDelayedUpdate', payload);
      },
    },
    total: {
      get() {
        return this.computedGet('sTotal');
      },
      set(payload) {
        this.computedSet('sTotal', payload);
      },
    },
    list: {
      get() {
        // courses | professions
        const key = [capitalize(this.type), 's'].join('');
        if (this.isTopSaleCoursesOrProfessions) {
          return this.$store.getters[
            `sale/processedTopSaleCoursesAndProfessions`
          ]({
            vm: this,
            basket: this.basket,
          });
        } else {
          return this.$store.getters[`sale/processed${key}`]({
            vm: this,
            basket: this.basket,
          });
        }
      },
      set(payload) {
        this.computedSet('s', payload);
      },
    },
    visible: {
      get() {
        return this.computedGet('sVisible');
      },
      set(payload) {
        this.computedSet('sVisible', payload);
      },
    },
    more: {
      get() {
        return this.computedGet('sMore');
      },
      set(payload) {
        this.computedSet('sMore', payload);
      },
    },
    title: {
      get() {
        return this.computedGet('sTitle');
      },
      set(payload) {
        this.computedSet('sTitle', payload);
      },
    },
    // Other
    isLatam() {
      return ['pe', 'co', 'ar', 'cl'].includes(
        this.$app?.config?.common?.currentCountry,
      );
    },
    sale() {
      return this.$store.state.sale?.sale;
    },
    saleId() {
      return this.sale?.id;
    },
    currentTypeId() {
      return this.$store.getters['courses/GET_TYPE_ID_BY_SLUG'](this.type);
    },
  },
  watch: {
    '$route.query': {
      async handler() {
        await this.fetchData();
      },
      deep: true,
    },
    buzySiblings: {
      handler(payload) {
        if (!payload && this.delayedUpdate) {
          // Отобразить данные на странице
          // Список номенклатур улетает в store через computed set()
          this.list = this.delayedUpdate?.list;
          this.total = this.delayedUpdate?.total;
          this.visible = this.delayedUpdate?.total;
          this.more = this.delayedUpdate?.more;
          this.lastToLoad = this.delayedUpdate?.lastToLoad;
          this.title = this.getTitle();

          this.delayedUpdate = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    /** Проверить, нужно ли загружать текущий тип  */
    needToLoadCurrentType() {
      const filterType = this.$props.isTopSaleCoursesOrProfessions
        ? []
        : this.parsedFilter.types?.[0];
      // Если тип в фильтре не заполнен - загружаем все типы
      if (!filterType) {
        return true;
      }
      // Если тип в фильтре совпадает с текущим типом - загружаем его
      const filterTypeSlug =
        this.$store.getters['courses/GET_TYPE_SLUG_BY_ID'](filterType);
      if (filterTypeSlug === this.type) {
        return true;
      }
      // Не загружаем текущий тип
      return false;
    },
    /** SSR Загрузка страницы или изменение фильтра */
    async fetchData() {
      // Запоминаем уникальный ключ запроса.
      // Если будет запрошено 2 результата одновременно и
      // второй придёт раньше первого - результат первого перезапишет последующий
      // и выдача будет неактуальна.
      // Чтобы избежать таких ситуаций - во время обработки результатов запроса нужно
      // сравнить ключ запроса и, если он не совпадает с хранящимся во vuex,
      // прекратить обработку результатов
      const fetchTiming = this.$props.isTopSaleCoursesOrProfessions
        ? ''
        : this.queryKey;

      // Включает класс blinking для секции
      this.buzy = true;

      // Оффсет сбрасывается, потому что fetchData вызывается
      // только при первом открытии страницы или изменении фильтра
      // При догрузке номенклатур вызывается loadMore
      this.offset = 0;

      // Проверить, нужно ли загружать текущий тип
      const needToLoadCurrentType = this.needToLoadCurrentType();
      if (!needToLoadCurrentType) {
        // Для браузеров - ждём, пока завершатся все запросы (и курсы, и профессии)
        // для сервера реактивность выключена и watch не сработает, поэтому нужно сразу же скрыть секцию
        // тогда с выключенным js не будет видно пустую секцию
        if (process.browser) {
          this.delayedUpdate = {
            visible: false,
          };
        } else this.visible = false;

        this.buzy = false;

        // Если тип ни разу не загружался
        if (!this.loaded) this.visible = false;

        this.loaded = true;

        return;
      }

      // Фильтр из filterMixin
      const country = this.$app?.config?.common?.currentCountry;
      const types = [this.currentTypeId];
      const filter = {
        ...this.parsedFilter,
        saleId: this.saleId,
        country,
        offset: this.offset,
        limit: this.limit,
        types,
      };

      // Получить данные с сервера
      const { total, data, lastToLoad } = await this.requestData({ filter });

      this.loaded = true;

      if (fetchTiming && fetchTiming !== this.queryKey) {
        return;
      }

      const visible = total > 0;
      const more = lastToLoad > 0;

      // Для браузеров - ждём, пока завершатся все запросы (и курсы, и профессии)
      // Данные будут обновлены в вотчере, когда все запросы завершатся
      if (process.browser) {
        this.buzy = false;
        this.delayedUpdate = {
          list: data,
          total: total,
          visible,
          more,
          lastToLoad,
        };
        return;
      }

      // Для сервера заполняем данные моментально
      this.buzy = false;
      this.list = data;
      this.total = total;
      this.visible = visible;
      this.more = more;
      this.lastToLoad = lastToLoad;
      this.title = this.getTitle();
    },
    /** Загрузить больше номенклатур с тем же фильтром */
    async loadMore() {
      this.buzy = true;

      // Фильтр из filterMixin. То же самое, что в fetchData
      const country = this.$app?.config?.common?.currentCountry;
      const types = this.$props.isTopSaleCoursesOrProfessions
        ? []
        : [this.currentTypeId];
      const filter = {
        ...this.parsedFilter,
        saleId: this.saleId,
        country,
        offset: this.offset,
        limit: this.limit,
        types,
      };

      const { data, total, lastToLoad } = await this.requestData({ filter });

      // Здесь не обрабатывается кейс с SSR и отложенное обновление данных,
      // потому что функция вызывается кликом по кнопке.
      // Новые данные добавляются к существующим на странице
      const newData = [...this.list, ...data];
      this.buzy = false;
      this.list = newData;
      this.total = total;
      this.lastToLoad = lastToLoad;
      this.more = lastToLoad > 0;
    },
    /** Получить данные с сервера */
    async requestData({ filter }) {
      const response = await this.$axios({
        method: 'GET',
        url: `${this.$nuxt.$config.CMS_REST_API}/public/v2/showcase/`,
        params: filter,
      });
      const data = response?.data?.data?.data || [];
      const total = response?.data?.data?.meta?.total || 0;

      // Обновить offset
      this.offset += this.limit;

      let lastToLoad = total - this.offset;
      if (lastToLoad > this.limit) {
        lastToLoad = this.limit;
      }

      return { data, total, lastToLoad };
    },
    /** Создать заголовок для секции карточек */
    getTitle() {
      const base = this.$t(`saleNew.cards.title.${this.type}.todos`);
      // Заголовок по выбранному в фильтре типу
      const selectedType = this.parsedFilter?.types?.[0];
      if (selectedType) {
        const selectedTypeSlug =
          this.$store.getters['courses/GET_TYPE_SLUG_BY_ID'](selectedType);
        if (selectedTypeSlug) {
          return this.$t(`saleNew.cards.title.${this.type}`);
        }
      }

      // Заголовок по выбранной в фильтре категории
      const directionId = this.parsedFilter?.directions?.[0];
      if (directionId) {
        const typeLine = this.$t(`saleNew.cards.title.${this.type}`);
        const directionLine = this.$t('saleNew.cards.title.category');
        const directionName =
          this.$store.getters['courses/GET_DIRECTION_FROM_ID'](
            directionId,
          )?.name;
        if (!directionName) return base;
        return `${typeLine} ${directionLine} ${directionName}`;
      }

      // Заголовок по дополнительным фильтрам
      if (this.parsedFilter?.jobGuarantee) {
        return this.$t(`saleNew.cards.title.${this.type}.jobGuarantee`);
      }

      return base;
    },
    /** Получить из стора значение для текущего типа */
    computedGet(key) {
      // key = sTitle
      // return key - coursesTitle | professionsTitle
      const fullKey = [this.type, key].join('');
      return this.$store.state.sale?.[fullKey];
    },
    /** Установить в сторе значение для текущего типа */
    computedSet(key, payload) {
      // key = sTitle
      // return key - sale/setCoursesTitle | sale/setProfessionsTitle
      const firstCharUpper = capitalize(this.type);
      const fullKey = ['sale/set', firstCharUpper, key].join('');
      this.$store.commit(fullKey, payload);
    },
    sendCompanyCatalogRequest(payload) {
      this.$emit('sendCompanyCatalogRequest', payload);
    },
  },
};
