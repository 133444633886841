import { render, staticRenderFns } from "./coursesMobileFilterContent.vue?vue&type=template&id=10f1c260&scoped=true&"
import script from "./coursesMobileFilterContent.vue?vue&type=script&lang=js&"
export * from "./coursesMobileFilterContent.vue?vue&type=script&lang=js&"
import style0 from "./coursesMobileFilterContent.vue?vue&type=style&index=0&id=10f1c260&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10f1c260",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoursesFilterBody: require('/app/landing_generator/components/courses/coursesFilterBody.vue').default})
