//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SaleCards from '@/pages/sale/new-design/components/cards/-saleCards.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TopSaleSale',
  components: {
    SaleCards,
  },
  props: {
    basket: {
      type: Array,
      default: () => [],
    },
    addToBasket: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('sale', [
      'computedTopSaleCoursesAndProfessions',
      'computedIsLoadingTopSaleCoursesAndProfessions',
    ]),
    needFallbackTopSaleCoursesOrProfessions() {
      return (
        !this.$store.state.sale.professionsVisible &&
        !this.$store.state.sale.coursesVisible
      );
    },
    isComputedTopSaleCoursesAndProfessionsAvailable() {
      return (
        this.computedTopSaleCoursesAndProfessions &&
        this.computedTopSaleCoursesAndProfessions.length > 0
      );
    },
  },
  watch: {
    needFallbackTopSaleCoursesOrProfessions: {
      async handler(value) {
        if (!value) return;
        if (this.isComputedTopSaleCoursesAndProfessionsAvailable) return;
        this.fetchTopSaleCoursesAndProfessions({ vm: this });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('sale', ['fetchTopSaleCoursesAndProfessions']),
    onCardClick(data) {
      this.$emit('cardClick', data);
    },
  },
};
