//
//
//
//
//
//
//

export default {
  name: 'CoursesIntro',
  computed: {
    direction() {
      const slug = this.$route.params?.courses;
      const direction =
        this.$store.getters['courses/GET_DIRECTION_FROM_SLUG'](slug);
      return direction;
    },
    seo() {
      const data = {
        title: '',
        subtitle: '',
      };

      this.getTopicSeo(data);
      this.getDirectionSeo(data);
      this.getDefaultSeo(data);

      return data;
    },
  },
  methods: {
    getTopicSeo(seo) {
      const topicSlug = this.$route.params?.topic;
      if (!topicSlug) return;

      const topic =
        this.$store.getters['courses/GET_TOPIC_FROM_SLUG'](topicSlug);
      if (!topic) return;

      seo.title = topic.metaH1;
      seo.subtitle = topic.metaDescription;
    },
    getDirectionSeo(seo) {
      if (!this.direction) return;

      seo.title = this.direction.title;
      seo.subtitle = this.direction.seoText;
    },
    getDefaultSeo(seo) {
      const defaultDirection =
        this?.$store?.getters?.['courses/GET_DIRECTION_FROM_SLUG']('cursos');

      if (!seo.title) seo.title = this.$t('courses.title');
      if (!seo.subtitle) seo.subtitle = defaultDirection?.metaDescription;
    },
  },
};
