//
//
//
//
//
//

import CoursesFilterBody from './coursesFilterBody';

export default {
  components: {
    CoursesFilterBody,
  },
  props: {
    namespace: {
      type: String,
      default: null,
    },
  },
};
