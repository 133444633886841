//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { VPopover, VClosePopover } from 'v-tooltip';
import onScroll from '../directives/onScroll';
import throttle from 'lodash/throttle';

export default {
  name: 'Tooltip',
  directives: {
    'close-popover': VClosePopover,
    scroll: onScroll,
  },
  components: {
    VPopover,
  },
  props: {
    design: {
      type: String,
      default: 'old', // new
    },
    popoverClass: {
      type: String,
      default: '',
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMounted: false,
    };
  },

  mounted() {
    this.isMounted = true;
  },
  methods: {
    toggle() {
      if (this.$refs.VPopover.isOpen) {
        this.$refs.VPopover.hide();
      } else {
        this.$refs.VPopover.show();
      }
    },
    onScroll: throttle(function () {
      if (this.$refs?.VPopover?.isOpen) {
        this.$refs.VPopover.hide();
      }
    }, 100),
  },
};
