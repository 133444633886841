//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 18,
    },
    steps: {
      type: Array,
      default: () => [1, 6, 12, 18],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => ({ min: 0, max: 100 }),
    },
  },
  data() {
    return {
      model: [],
      buzy: false,
    };
  },
  methods: {
    onChange(e) {
      // TODO gtm?
      this.$emit('input', e);
    },
  },
};
