export default function () {
  const meta = {
    title: '',
    description: '',
    keywords: '',
  };

  const directionMetaFunction = directionMeta.bind(this);
  const topicMetaFunction = topicMeta.bind(this);
  const defaultMetaFunction = defaultMeta.bind(this);

  directionMetaFunction(meta);
  topicMetaFunction(meta);
  defaultMetaFunction(meta);

  return {
    title: meta.title,
    meta: [
      {
        hid: 'keywords',
        name: 'keywords',
        content: meta.keywords,
      },
      {
        hid: 'description',
        name: 'description',
        content: meta.description,
      },
      {
        hid: 'og:description',
        name: 'og:description',
        property: 'og:description',
        content: meta.description,
      },
      {
        hid: 'og:title',
        name: 'og:title',
        property: 'og:title',
        content: meta.title,
      },
      {
        hid: 'og:site_name',
        name: 'og:site_name',
        property: 'og:site_name',
        content: 'EBAC Online',
      },
      {
        hid: 'og:url',
        name: 'og:url',
        property: 'og:url',
        content: `https://${this.$app.config.current.domain}${this.$route.path}`,
      },
      {
        hid: 'og:locale',
        name: 'og:locale',
        property: 'og:locale',
        content: this.$app?.config?.current?.localeName,
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: `https://${
          this.$store?.state?.host
        }${require(`~/assets/${this.$app?.config?.common?.currentCountry}/svg/ebac-logo.svg`)}`,
      },
      {
        hid: 'og:type',
        name: 'og:type',
        property: 'og:type',
        content: 'website',
      },
    ],
    bodyAttrs: {
      class: 'body--courses-list',
    },
  };
}

function directionMeta(meta) {
  const directionSlug = this.$route.params?.courses;
  if (!directionSlug) return;

  const direction =
    this.$store.getters['courses/GET_DIRECTION_FROM_SLUG'](directionSlug);
  if (!direction) return;

  meta.title = direction.metaTitle;
  meta.description = direction.metaDescription;
  meta.keywords = direction.metaKeywords;
}

function topicMeta(meta) {
  const topicSlug = this.$route.params?.topic;
  if (!topicSlug) return;

  const topic = this.$store.getters['courses/GET_TOPIC_FROM_SLUG'](topicSlug);
  if (!topic) return;

  meta.title = topic.metaTitle;
  meta.description = topic.metaDescription;
  meta.keywords = topic.metaKeywords;
}

function defaultMeta(meta) {
  const defaultDirection =
    this?.$store?.getters?.['courses/GET_DIRECTION_FROM_SLUG']('cursos');

  if (!meta.title) meta.title = defaultDirection?.metaTitle;
  if (!meta.description) meta.description = defaultDirection?.metaDescription;
  if (!meta.keywords) meta.keywords = defaultDirection?.metaKeywords;
}
