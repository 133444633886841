//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldMixin from '@/mixins/field';

export default {
  name: 'ButtonToggle',
  mixins: [fieldMixin],
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    itemText: {
      type: String,
      default: 'label',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    nativeUrlName: {
      type: String,
      default: '',
    },
    nativeUrlParam: {
      type: String,
      default: '',
    },
    enableArrow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  async fetch() {
    // Взято из fieldMixin
    await this.fillOptions();
  },
  methods: {
    select(item) {
      if (this.isActive(item)) return;
      this.$emit('input', item[this.itemValue]);
    },
    isActive(item) {
      return this.value === item[this.itemValue];
    },
    nativeHref(slug) {
      if (!this.nativeUrlName || !this.nativeUrlParam) return;
      const locale = this.$app.config.current.localeName;
      const route = this.$router.resolve({
        name: `${this.nativeUrlName}___${locale}`,
        params: {
          [this.nativeUrlParam]: slug,
        },
      });

      return route.href;
    },
  },
};
