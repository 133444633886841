//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CoursesCards from '@/components/courses/coursesCards';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TopSaleCursos',
  components: {
    CoursesCards,
  },
  computed: {
    ...mapGetters('courses', [
      'computedTopSaleCoursesAndProfessions',
      'computedIsLoadingTopSaleCoursesAndProfessions',
    ]),
    needFallbackTopSaleCoursesOrProfessions() {
      return (
        !this.$store.state.courses.professionsVisible &&
        !this.$store.state.courses.coursesVisible
      );
    },
    isComputedTopSaleCoursesAndProfessionsAvailable() {
      return (
        this.computedTopSaleCoursesAndProfessions &&
        this.computedTopSaleCoursesAndProfessions.length > 0
      );
    },
  },
  watch: {
    needFallbackTopSaleCoursesOrProfessions: {
      async handler(value) {
        if (!value) return;
        if (this.isComputedTopSaleCoursesAndProfessionsAvailable) return;
        this.fetchTopSaleCoursesAndProfessions({ vm: this });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('courses', ['fetchTopSaleCoursesAndProfessions']),
    onCardClick(data) {
      this.$emit('cardClick', data);
    },
  },
};
