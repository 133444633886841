//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CourseCard from './courseCard.vue';

export default {
  name: 'CoursesCards',
  components: {
    CourseCard,
  },
  props: {
    nomenclatureType: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    buzy: {
      type: Boolean,
      default: false,
    },
    more: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 10,
    },
    isTopSaleCoursesOrProfessions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    lastToLoadText() {
      return `${this.$t('courses.cards.more')} ${this.lastToLoad} ${this.$tc(
        this.nomenclatureType,
        this.lastToLoad,
      )}`;
    },
    lastToLoad() {
      const last = this.total - this.list?.length;
      if (last < 1) return;

      return last > this.limit ? this.limit : last;
    },
  },
};
