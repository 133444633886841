//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EButton from '@/components/d0822/fields/button.vue';
import viewportMixin from '@/mixins/viewport.mixin';
import CoursesMobileFilterContent from './coursesMobileFilterContent';

export default {
  components: {
    EButton,
    CoursesMobileFilterContent,
  },
  mixins: [viewportMixin],
  props: {
    namespace: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      applied: false,
    };
  },
  computed: {
    selectedFiltersCount() {
      return this.$store.state.filters.cursosList?.selectedCount;
    },
    display: {
      get() {
        return this.$store.state.courses.mobileFilterOpened;
      },
      set(payload) {
        this.debug(
          { name: 'display coursesMobileFilter' },
          'arg display = %s',
          payload,
        );

        this.$store.commit('courses/setMobileFilterOpened', payload);
        payload ? this.$emit('open') : this.$emit('close', this.applied);
        this.applied = false;
      },
    },
  },
  methods: {
    apply() {
      this.applied = true;

      this.close();
    },
    close() {
      if (this.display) this.display = false;
    },
  },
};
