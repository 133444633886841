//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Tooltip from '@/components/tooltip.vue';
import ViewportMixin from '@/mixins/viewport.mixin';

export default {
  components: {
    Tooltip,
  },
  mixins: [ViewportMixin],
  props: {
    tooltip: {
      type: Object,
      default: () => ({ title: '', text: '' }),
    },
    jobGuarantee: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mobileDialog: false,
    };
  },
  computed: {
    title() {
      if (this.jobGuarantee) {
        return this.$t('saleNew.tooltip.jobGuarantee.title');
      }

      return this.tooltip.title;
    },
    description() {
      if (this.jobGuarantee) {
        return this.$t('saleNew.tooltip.jobGuarantee');
      }

      return this.tooltip.text;
    },
  },
  methods: {
    onClick() {
      if (!this.isMobile) return;

      this.mobileDialog = true;
    },
  },
};
