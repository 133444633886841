//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const TAIL = 4;
const HEAD = 4;

export default {
  name: 'Pagination',
  props: ['total', 'limit'],
  computed: {
    lastPage() {
      const total = this.total;
      const limit = this.limit;
      return Math.ceil(total / limit);
    },
    currentPage() {
      return Math.min(Number(this.$route.query.page) || 1, this.lastPage);
    },
    availableTail() {
      const currentPage = this.currentPage;
      const firstPage = 1;
      const offset = Math.max(currentPage - firstPage - 1, 0);
      return TAIL < offset ? TAIL : offset;
    },
    availableHead() {
      const currentPage = this.currentPage;
      const lastPage = this.lastPage;
      return Math.max(Math.min(lastPage - currentPage - 1, HEAD), 0);
    },
  },
};
