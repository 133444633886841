//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldMixin from '@/mixins/field';

export default {
  mixins: [fieldMixin],
  props: {
    checkbox: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: 'label',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    value: {
      type: [Array, Number, String],
      default: () => [],
    },
    primary: {
      type: Boolean,
      default: false,
    },
    showLimit: {
      type: Number,
      default: 0,
    },
    searchPlaceholder: {
      type: String,
      default: '',
    },
    more: {
      type: String,
      default: 'More',
    },
    less: {
      type: String,
      default: 'Less',
    },
    optionsAction: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      options: [],
      opened: false,
      search: '',
      displayTooltip: false,
    };
  },
  async fetch() {
    await this.fillOptions();
  },
  computed: {
    limitedOptions() {
      // Если элементы ограничены по лимиту и поле не развёрнуто
      if (this.showLimit && !this.opened) {
        // Обязательно выводим все параметры, которые уже выбраны
        // но могут быть свёрнуты
        if (this.value.length) {
          try {
            const options = JSON.parse(JSON.stringify(this.options));
            options.sort((a, b) => {
              const firstIndex = this.value.indexOf(a[this.itemValue]);
              const secondIndex = this.value.indexOf(b[this.itemValue]);
              const first = firstIndex === -1 ? 1 : 2;
              const second = secondIndex === -1 ? 1 : 2;

              return second - first;
            });

            if (this.value.length >= this.showLimit) {
              return options.slice(0, this.value.length);
            } else {
              return options.slice(0, this.showLimit);
            }
          } catch (e) {
            return this.options;
          }
        }

        // Если ничего не выбрано - просто вывести ограниченное количество опшенов
        return this.options.slice(0, this.showLimit);
      }

      // Если поле открыто и поиск не пустой
      if (this.opened && this.search) {
        return this.options.filter((i) => {
          const text = i[this.itemText].toLowerCase();
          const search = this.search.toLowerCase();
          return text.includes(search);
        });
      }

      return this.options;
    },
    readyToShowMore() {
      if (!this.showLimit) return false;
      return this.options.length > this.limitedOptions.length && !this.opened;
    },
    readyToShowLess() {
      return this.opened;
    },
    moreCount() {
      if (!this.readyToShowMore) return 0;
      return this.options.length - this.limitedOptions.length;
    },
    tooltip() {
      return !!this.$slots?.tooltip;
    },
    searchLabel() {
      if (this.searchPlaceholder) return this.searchPlaceholder;
      return this.$t('courses.filter.search.placeholder');
    },
  },
  methods: {
    isSelected(payload) {
      if (this.checkbox) {
        return this.value.includes(payload);
      } else {
        return this.value === payload;
      }
    },
    toggleOpened() {
      this.opened = !this.opened;
    },
    onInput(item) {
      const index = this.options.findIndex(
        (i) => i[this.itemValue] === item[this.itemValue],
      );

      const payload = this.options[index]?.[this.itemValue];

      if (!this.checkbox) {
        if (this.value === payload) return;
        this.$emit('input', payload);
      } else {
        const findedIndex = this.value.findIndex((i) => i === payload);
        const oldValue = this.value?.length ? [...this.value] : [];

        if (findedIndex !== -1) {
          oldValue.splice(findedIndex, 1);
          this.$emit('input', oldValue);
        } else {
          this.$emit('input', [...oldValue, payload]);
        }
      }
    },
    showTooltip() {
      this.$refs.tooltip.toggle();
    },
  },
};
