//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    price: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isMx() {
      return this.$config.COUNTRY === 'mx';
    },
  },
};
