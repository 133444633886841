//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CoursesCards from '@/components/courses/coursesCards';
import ButtonToggle from '../d0822/fields/buttonToggle.vue';
import filterMixin from '@/mixins/filter';
import coursesMobileFitler from './coursesMobileFitler.vue';
import CoursesFilterBody from './coursesFilterBody';
import Pagination from '../../components/pagination';
import TopSale from '@/components/d0822/components/topSale';
import { isEmpty } from 'lodash';

export default {
  name: 'CoursesFilter',
  components: {
    CoursesCards,
    ButtonToggle,
    coursesMobileFitler,
    CoursesFilterBody,
    TopSale,
    //The reason because Pagination underlined
    //is because a Pagination used inside <noscript> html tag
    // eslint-disable-next-line vue/no-unused-components
    Pagination,
  },
  mixins: [filterMixin],
  data() {
    return {
      coursesOffset: 0,
      coursesMore: false,

      professionsOffset: 0,
      professionsMore: false,

      limit: 10,
      lastFetchTiming: 1,

      filterNamespace: 'cursosList',
    };
  },
  async fetch() {
    await this.fetchData();
    await this.getAllNomenclaturesSeo();
  },
  computed: {
    firstTimeLoaded: {
      get() {
        return this.$store.state.courses.firstTimeLoaded;
      },
      set() {
        return this.$store.commit('courses/setFirstTimeLoaded', true);
      },
    },
    isBr() {
      return this.$nuxt.$config.COUNTRY === 'br';
    },
    isMx() {
      return this.$nuxt.$config.COUNTRY === 'mx';
    },
    coursesVisible: {
      get() {
        return this.$store.state.courses.coursesVisible;
      },
      set(payload) {
        this.$store.commit('courses/setCoursesVisible', payload);
      },
    },
    coursesBuzy: {
      get() {
        return this.$store.state.courses.coursesBuzy;
      },
      set(payload) {
        this.$store.commit('courses/setCoursesBuzy', payload);
      },
    },
    coursesTotal: {
      get() {
        return this.$store.state.courses.coursesTotal;
      },
      set(payload) {
        this.$store.commit('courses/setCoursesTotal', payload);
      },
    },
    professionsVisible: {
      get() {
        return this.$store.state.courses.professionsVisible;
      },
      set(payload) {
        this.$store.commit('courses/setProfessionsVisible', payload);
      },
    },
    professionsBuzy: {
      get() {
        return this.$store.state.courses.professionsBuzy;
      },
      set(payload) {
        this.$store.commit('courses/setProfessionsBuzy', payload);
      },
    },
    professionsTotal: {
      get() {
        return this.$store.state.courses.professionsTotal;
      },
      set(payload) {
        this.$store.commit('courses/setProfessionsTotal', payload);
      },
    },

    dataProfessions() {
      return this.$store.state.courses.professionsList;
    },
    dataCourses() {
      return this.$store.state.courses.coursesList;
    },
    isLatam() {
      return ['pe', 'co', 'ar', 'cl'].includes(
        this.$app?.config?.common?.currentCountry,
      );
    },

    courses: {
      get() {
        return this.filter.courses;
      },
      set(payload) {
        return this.filterFieldUpdate('courses', payload);
      },
    },
    textSearch: {
      get() {
        return this.filter.textSearch;
      },
      set(payload) {
        return this.filterFieldUpdate('textSearch', payload);
      },
    },
  },
  watch: {
    '$route.query': {
      async handler(payload) {
        if (isEmpty(payload)) {
          this.clear();
          return;
        }

        await this.fetchData();
        await this.getAllNomenclaturesSeo();
      },
      deep: true,
    },
  },
  created() {
    this.createFilterOnFirstFetch();
  },
  methods: {
    createFilterOnFirstFetch() {
      let fields = [
        {
          name: 'courses',
          clearable: true,
          urlParam: true,
          urlValueGetter: 'courses/GET_DIRECTION_SLUG_FROM_ID',
          serverValueGetter: 'courses/GET_DIRECTION_ID_FROM_SLUG',
          toArray: true,
          alias: 'directions',
          nullState: 'cursos',
          routeName: 'courses',
        },
        { name: 'textSearch', clearable: true, urlQuery: true },
        {
          name: 'types',
          clearable: true,
          urlQuery: true,
          urlValueGetter: 'courses/GET_TYPE_SLUG_BY_ID',
          serverValueGetter: 'courses/GET_TYPE_ID_BY_SLUG',
          toArray: true,
        },
        {
          name: 'durationMax',
          urlQuery: true,
          clearable: true,
          default: 18,
        },
        {
          name: 'durationMin',
          urlQuery: true,
          clearable: true,
          default: 1,
        },

        {
          name: 'page',
          urlQuery: true,
        },
      ];

      if (this.isBr) {
        fields = [
          ...fields,
          {
            name: 'topic',
            urlParam: true,
            array: true,
            routeName: 'coursesTopic',
            clearable: true,
          },
          {
            name: 'free',
            urlQuery: true,
            clearable: true,
            boolean: true,
            default: false,
          },
        ];
      }

      if (this.$app.config.current.jobGuarantee) {
        fields.push({
          name: 'jobGuarantee',
          urlQuery: true,
          clearable: true,
          boolean: true,
          default: false,
        });
      }

      this.createFilter(this.filterNamespace, fields);
    },
    async fetchData() {
      const fetchTiming = this.queryKey;

      try {
        this.coursesOffset = 0;
        this.professionsOffset = 0;

        // ! объект filter нельзя изменять, так как он computed
        const filter = this.parsedFilter;
        const courses = !filter.types || filter.types?.[0] === '0';
        const professions = !filter.types || filter.types?.[0] === '1';

        // Promise.all используется для параллельного запуска запросов
        if (courses && professions) {
          this.coursesBuzy = true;
          this.professionsBuzy = true;
          await Promise.all([
            this.fetchProfessions({ fetchTiming }),
            this.fetchCourses({ fetchTiming }),
          ]);
        } else {
          if (professions) {
            this.professionsBuzy = true;
            await this.fetchProfessions({ fetchTiming });
          }
          if (courses) {
            this.coursesBuzy = true;
            await this.fetchCourses({ fetchTiming });
          }
        }

        if (fetchTiming && fetchTiming !== this.queryKey) return;

        if (process.browser) {
          // const scaledOffset = calculateScaledOffset(this.$refs.filter);
          // this.$scrollTo('.courses-filter', 300, {
          //   offset: scaledOffset,
          // });
        }

        // Убрать со страницы тот тип номеклатур, который не был запрошен
        // (если запрошен был только один из них)
        if (courses && professions) return;

        if (!courses) {
          this.$store.commit('courses/setCourses', []);
          this.coursesVisible = false;
        }

        if (!professions) {
          this.$store.commit('courses/setProfessions', []);
          this.professionsVisible = false;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchProfessions({ gtm = false, fetchTiming }) {
      if (gtm) {
        this.$gtm.push({
          event: 'autoEvent',
          eventCategory: 'click',
          eventAction: 'show_more',
          eventLabel: 'cursos_page',
        });
      }

      this.professionsBuzy = true;
      const filter = this.parsedFilter;
      const { total, data } = await this.getData(filter, 'professions');
      this.firstTimeLoaded = true;

      if (fetchTiming && fetchTiming !== this.queryKey) {
        this.professionsBuzy = false;
        return;
      }

      // Если offset больше нуля - значит данные добавляются к старым
      const resultData = this.professionsOffset
        ? [...this.dataProfessions, ...data]
        : data;

      this.$store.commit('courses/setProfessions', resultData || []);

      this.professionsTotal = total;
      this.professionsOffset = this.professionsOffset += this.limit;
      this.professionsVisible = !!total;
      this.professionsBuzy = false;

      // Если total === 0 или offset превысил максимальное значение - скрыть кнопку More
      this.professionsMore = !!total && total > this.professionsOffset;
    },
    async fetchCourses({ gtm = false, fetchTiming }) {
      if (gtm) {
        this.$gtm.push({
          event: 'autoEvent',
          eventCategory: 'click',
          eventAction: 'show_more',
          eventLabel: 'cursos_page',
        });
      }

      this.coursesBuzy = true;
      const filter = this.parsedFilter;
      const { total, data } = await this.getData(filter, 'courses');
      this.firstTimeLoaded = true;

      if (fetchTiming && fetchTiming !== this.queryKey) {
        this.coursesBuzy = false;
        return;
      }

      // Если offset больше нуля - значит данные добавляются к старым
      const resultData = this.coursesOffset
        ? [...this.dataCourses, ...data]
        : data;

      this.$store.commit('courses/setCourses', resultData || []);

      this.coursesTotal = total;
      this.coursesOffset = this.coursesOffset += this.limit;
      this.coursesVisible = !!total;
      this.coursesBuzy = false;

      // Если total === 0 или offset превысил максимальное значение - скрыть кнопку More
      this.coursesMore = !!total && total > this.coursesOffset;
    },
    async getData(
      filter,
      type = 'courses',
      url = '/public/v2/showcase/',
      method = 'GET',
      ignoreOffset,
      ignoreLimit,
    ) {
      const country = this.$app?.config?.common?.currentCountry;

      let offset = 0;
      let types;
      if (type === 'courses') {
        offset = this.coursesOffset;
        types = ['0'];
      }

      if (type === 'professions') {
        offset = this.professionsOffset;
        types = ['1'];
      }

      if (this.$route.query.page) {
        offset = (this.$route.query.page - 1) * this.limit;
      }
      if (ignoreOffset) offset = 0;

      let response;

      let payload;
      if (method === 'POST') payload = 'data';
      if (method === 'GET') payload = 'params';

      response = await this.$axios({
        method,
        url: `${this.$nuxt.$config.CMS_REST_API}${url}`,
        [payload]: {
          ...filter,
          offset: !ignoreOffset ? offset : 0,
          limit: !ignoreLimit ? this.limit : 9999,
          country,
          latam: this.isLatam,
          types,
        },
      });

      let { meta, data } = response.data.data;
      if (!data) data = response.data.data;
      const total = meta?.total;

      if (this.$app.config.current.newDomainName) {
        data = data.map((nomenclature) => ({
          ...nomenclature,
          pageUrl:
            (nomenclature.pageUrl &&
              nomenclature.pageUrl.replace(
                new URL(nomenclature.pageUrl).origin,
                this.$app.config.current.newDomainName,
              )) ||
            '',
        }));
      }

      return { total, data };
    },
    async getAllNomenclaturesSeo() {
      const filter = JSON.parse(JSON.stringify(this.parsedFilter));
      if (!filter.types) filter.types = ['0', '1'];

      let { data } = await this.getData(
        filter,
        'all',
        '/public/nomenclature-filter',
        'GET',
        true,
        true,
      );

      if (!data) return;
      data.sort((a, b) => a.type - b.type);

      this.$store.commit('courses/setNomenclaturesAll', data);
    },
    onCardClick(data) {
      this.$gtm.push({
        event: 'autoEvent',
        eventCategory: 'click',
        eventAction: 'offer',
        eventLabel: data.name,
      });
    },
  },
};
