import { render, staticRenderFns } from "./coursesFilter.vue?vue&type=template&id=6869be22&scoped=true&"
import script from "./coursesFilter.vue?vue&type=script&lang=js&"
export * from "./coursesFilter.vue?vue&type=script&lang=js&"
import style0 from "./coursesFilter.vue?vue&type=style&index=0&id=6869be22&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6869be22",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ETextField: require('/app/landing_generator/node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/textField/index.vue').default,CoursesMobileFitler: require('/app/landing_generator/components/courses/coursesMobileFitler.vue').default,CoursesFilterBody: require('/app/landing_generator/components/courses/coursesFilterBody.vue').default,CoursesCards: require('/app/landing_generator/components/courses/coursesCards.vue').default,Pagination: require('/app/landing_generator/components/pagination.vue').default})
