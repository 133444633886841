import { render, staticRenderFns } from "./coursesFilterBody.vue?vue&type=template&id=4fce2d24&scoped=true&"
import script from "./coursesFilterBody.vue?vue&type=script&lang=js&"
export * from "./coursesFilterBody.vue?vue&type=script&lang=js&"
import style0 from "./coursesFilterBody.vue?vue&type=style&index=0&id=4fce2d24&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fce2d24",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ECheckbox: require('/app/landing_generator/node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/checkbox/index.vue').default})
