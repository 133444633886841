// Создать фильтр в компоненте, после подключения миксина:
// - this.createFilter('<название фильтра>', this.fields);

// Из-за особенностей реактивности Vue мы не можем полноценно
// использовать объекты в сторе, если они изначально не указаны в state
// поэтому при создании нового фильтра нужно добавить пустой объект
// для его namespace в state модуля /store/filters.js

// Поля указать в data:
// - data() {
// -   return {
// -     fields: [
// -       { name: 'search', clearable: true, urlQuery: true },
// -       { name: 'directions',
// -         array: true,
// -         clearable: true,
// -         urlParam: true,
// -         urlValueGetter: 'eventsList/GET_DIRECTION_SLUG_FROM_ID',
// -         serverValueGetter: 'eventsList/GET_DIRECTION_ID_FROM_SLUG',
// -         default: 3,
// -       },
// -       { name: 'types',
// -         array: true,
// -         urlQuery: true,
// -         clearable: true },
// -       { name: 'status' }
// -     ]
// -   };
// - },

// Во vue компоненте получаем одно поле через конструкцию:
// - computed: {
// -   search: {
// -     get() {
// -       return this.filter.search
// -     },
// -     set(payload) {
// -       return this.filterFieldUpdate('search', payload)
// -     }
// -   }
// - },

// Для получения фильтра (не для создания)
// Подключаем миксин, добавляем в data:
// - filterNamespace: '<название фильтра>'
// в fetch поулчаем данные фильтра:
// - const filterData = this.parsedFilter;

// Если указать this.freezeFilter = { payload: true }
// фильтр заморозится и его изменения не будут обновлять url страницы.
// Выключить заморозку можно с отменой действий: this.freezeFilter = { payload: false }
// И с сохранением фильтра: this.freezeFilter = { payload: false, ignore: false }
// Можно использовать для заполнения фильтр в мобильном всплывающем окне
// когда обновление должно происходить по нажатию кнопки apply

//// Параметры для field:

// name - название поля

// urlParam - это поле будет попадать в route.params

// urlQuery - это поле будет попадать в route.query

// array - поле обрабатывается как массив

// clearable - поле можно обнулить через функцию clear

// urlValueGetter - поле будет получать значение для адреса из стора
// например: в селекте выбираются id направлений, но в адресе страницы
// мы хотим указывать их slug. В urlValueGetter указываем 'courses/GET_DIRECTION_SLUG_FROM_ID'
// при создании роутера это значение будет обрабатываться и учитываться

// serverValueGetter - поле будет получать значение для сервера из стора
// например: мы указали urlValueGetter и нужно при обработке параметров в адресе страницы
// получать вместо slug направления его id для отправки на сервер в виде фильтра, тогда
// указывается courses/GET_DIRECTION_ID_FROM_SLUG

// toArray - поле будет обрабатываться как простое значение, но в значении parsedField
// превращается в массив через payload = [payload] для удобной отправки на сервер.

// alias - если поле с urlParam нужно отправлять на сервер под другим именем

// nullState - если значение поля равно значению nullState, то оно считается незаполненным
// Нужно для корректной обработки параметров, где поле всегда не null
// (например, направление у курсов всегда равно cursos, когда не заполнено, поэтому
// для направлений курсов nullState = 'cursos')

// routeName - указываается для полей с urlParam и используется
// для редиректа на роут, у которого есть этот параметр (например: courses и coursesTopic)

// boolean - поле обрабаытвается, как булевое значение. На данный момент если
// такое поле равно false - оно не учитывается в фильтре
// TODO добавить новый параметр allowFalse для разрешения обработки значения false

// unique - поля с таким ключом обрабатываются как радиокнопка - может быть активно только одно
// поле одновременно

export default {
  data() {
    return {
      filterNamespace: '',
    };
  },
  computed: {
    // Все поля фильтра.
    filter() {
      if (!this.filterNamespace) return undefined;
      return this.$store.state.filters[this.filterNamespace].filter;
    },

    // Все заполненные поля фильтра. Убраны пустые массивы и значения
    // Для отправки на сервер в запросе
    parsedFilter() {
      if (!this.filterNamespace) return undefined;
      const rawFilter = {
        ...this.$store.state.filters[this.filterNamespace].filter,
      };
      const filter = {};

      let uniqueFieldFilled = false;
      for (let [key, value] of Object.entries(rawFilter)) {
        const field = this.filterFields.find((i) => i.name === key);
        let parsedValue = this.parseFieldValue(field, value);
        if (!parsedValue) continue;

        // Заблокировать заполнение последующих полей
        // с флагом unique: true
        if (field.unique) {
          if (!uniqueFieldFilled) {
            uniqueFieldFilled = true;
          } else continue;
        }

        // Обрабатываем toArray и alias только перед отправкой на сервер
        if (field.toArray) parsedValue = [parsedValue];
        if (field.alias) {
          filter[field.alias] = parsedValue;
        } else {
          filter[key] = parsedValue;
        }
      }

      return filter;
    },

    // Описание всех полей фильтра
    filterFields() {
      if (!this.filterNamespace) return undefined;
      return this.$store.state.filters[this.filterNamespace].fields;
    },

    // Доступна ли очистка фильтра
    clearable() {
      if (!this.filterNamespace) return false;
      return this.$store.state.filters[this.filterNamespace].clearable;
    },

    selectedFiltersCount() {
      if (!this.filterNamespace) return false;
      return this.$store.state.filters[this.filterNamespace].selectedCount;
    },

    filterFreeze: {
      get() {
        return this.$store.state.filters[this.filterNamespace].freeze;
      },
      set({ payload, ignore = true }) {
        this.$store.commit('filters/SET_FREEZE', {
          namespace: this.filterNamespace,
          payload,
          ignore,
        });

        if (!payload && !ignore) {
          this.fillUrlDataFromFilter();
          this.checkIfClearableAndSetCount();
        }
      },
    },
    filterFreezedState() {
      return this.$store.state.filters[this.filterNamespace].freezedState;
    },
    queryKey() {
      return this.$store.state.filters[this.filterNamespace].queryKey;
    },
  },
  methods: {
    // Создание фильтра и его сохранение в сторе
    createFilter(namespace, fields) {
      if (!namespace || !fields?.length) return;
      this.filterNamespace = namespace;

      // Заполнить значения фильтра по-умолчанию
      let filter = {};
      fields.forEach((field) => {
        if (field.name) {
          if (field.default) filter[field.name] = field.default;
          else if (field.array) filter[field.name] = [];
          else if (field.boolean) filter[field.name] = false;
          else filter[field.name] = '';
        }
      });

      // Получить значения из url
      filter = { ...filter, ...this.getFilterDataFromUrl(fields) };

      // Сохранить фильтр в Store
      this.$store.dispatch('filters/SAVE', { namespace, filter, fields });
      this.checkIfClearableAndSetCount();
    },

    // Обновить все поля фильтра в сторе
    filterUpdate(payload) {
      this.debug({ name: 'filterUpdate' });

      this.$store.dispatch('filters/UPDATE', {
        namespace: this.filterNamespace,
        payload,
      });

      if (this.filterFreeze) return;
      this.fillUrlDataFromFilter();
      this.checkIfClearableAndSetCount();
    },

    // Обновить одно поле фильтра в сторе
    filterFieldUpdate(fieldName, payload) {
      this.debug({ name: 'filterFieldUpdate' }, 'arg fieldName, payload = %O', {
        fieldName,
        payload,
      });

      this.$store.dispatch('filters/UPDATE_FIELD', {
        namespace: this.filterNamespace,
        fieldName,
        payload,
      });

      if (this.filterFreeze) return;
      this.fillUrlDataFromFilter();
      this.checkIfClearableAndSetCount();
    },

    // Получить данные из URL и вернуть их
    getFilterDataFromUrl(fields) {
      if (!fields) fields = this.filterFields;

      const { query, params } = this.$route;
      const filter = {};

      for (let [key, value] of Object.entries({ ...params, ...query })) {
        if (!value) continue;

        let field = fields.find((i) => i.name === key);
        if (!field) continue;

        let parsedValue = this.parseFieldValue(field, value);
        if (!parsedValue) continue;

        parsedValue = this.getFieldValueByAction(field, parsedValue, {
          server: true,
        });

        if (!parsedValue) continue;
        filter[key] = parsedValue;
      }

      return filter;
    },

    // Заполнить данные из фильтра в URL
    fillUrlDataFromFilter() {
      const route = {
        query: {},
        params: {},
        name: '',
      };

      for (let [key, value] of Object.entries(this.filter)) {
        const field = this.filterFields.find((i) => i.name === key);
        if (!field) continue;

        let parsedValue = this.parseFieldValue(field, value);
        if (!parsedValue) {
          // Если значение параметра c urlParam сбрасывается до null или '',
          // то нужно явно выставить это значение в объекте, чтобы
          // страница корректно обновилась
          if (field.urlParam) parsedValue = '';
          else continue;
        }

        parsedValue = this.getFieldValueByAction(field, parsedValue, {
          url: true,
        });
        this.fillQueryAndParamsFromField(field, parsedValue, route);
      }

      const locale = this.$app.config.current.localeName;
      const routeName = route.name ? `${route.name}___${locale}` : null;

      // Отдельно записать все поля в query, которые не описаны в фильтре
      // это нужно для сохранения utm-меток, ab-тестов и других параметров
      // которые могут оказаться в адресе страницы
      // и не должны стираться после обновления фильтра
      const otherQuery = {};
      const filterFieldsKeys = this.filterFields.map((i) => i.name);
      const routeQuery = this.$route.query;

      for (const key in routeQuery) {
        const routeQueryItem = routeQuery[key];
        if (filterFieldsKeys.includes(key)) continue;
        otherQuery[key] = routeQueryItem;
      }

      // Обновить путь страницы
      const name = routeName || this.$route.name;
      const query = { ...route.query, ...otherQuery };
      const params = route.params;

      this.$router.replace({ name, query, params }).catch(() => {});
    },

    checkIfClearableAndSetCount() {
      let count = 0;
      this.filterFields.forEach((field) => {
        const value = this.filter[field.name];
        if (field.clearable) {
          let defaultValue = field.default;
          let currentValue = value;

          if (!field.array && defaultValue && currentValue) {
            defaultValue = defaultValue.toString();
            currentValue = value.toString();
          }

          if (defaultValue !== currentValue) {
            if (field.array && value?.length) count++;
            else if (!field.array && !!value) count++;
          }
        }
      });

      this.$store.commit('filters/SET_CLEARABLE_AND_COUNT', {
        namespace: this.filterNamespace,
        count,
      });
    },

    // Очистить фильтр
    clear() {
      this.debug({ name: 'filterClear' });

      let filter = {};
      this.filterFields.forEach((field) => {
        if (field.name) {
          if ('default' in field) filter[field.name] = field.default;
          else if (field.array) filter[field.name] = [];
          else filter[field.name] = '';
        }
      });

      this.$store.dispatch('filters/SAVE', {
        namespace: this.filterNamespace,
        filter,
        fields: this.filterFields,
      });

      if (this.filterFreeze) return;
      this.fillUrlDataFromFilter();
    },

    // Utils
    parseFieldValue(field, payload) {
      if (field.boolean) return !!payload;

      if (!payload) return undefined;

      if (!field.array) {
        return payload;
      }

      if (Object.prototype.toString.call(payload) !== '[object Array]') {
        return [payload];
      }

      if (!payload.length) return undefined;
      return payload;
    },

    // Получить другую форму значения.
    // Например: у нас есть directions в виде [6, 7];
    // Мы отправляем на сервер directions в неизменном виде
    // Но для url нам нужно заменить [6, 7] на ['games', 'programmacio'].
    // Значение получаем через action в field.serverValueGetter
    // или field.urlValueGetter соответственно
    getFieldValueByAction(field, payload, { server = false, url = false }) {
      if (!server && !url) return payload;

      let action;
      if (server && field.serverValueGetter) {
        action = field.serverValueGetter;
      }

      if (url && field.urlValueGetter) {
        action = field.urlValueGetter;
      }

      if (action) {
        if (!field.array) return this.$store.getters[action](payload);

        return [...payload].map((i) => this.$store.getters[action](i));
      }

      return payload;
    },

    // Добавить значения в объекты query и params
    // query и params - ссылочные, не возвращаются из функции через return
    fillQueryAndParamsFromField(field, payload, route) {
      // Если массив params не пустой - значит игнорируем значение нового поля
      // и заполняем его в query. У полей приоритет попадания в params в том же
      // порядке, что при объявлении их в методе createFilter
      const paramsKeys = Object.keys(route.params);
      const fieldInParam = this.filterFields.find(
        (i) => i.name === paramsKeys[0],
      );

      const valueInParam = route.params[paramsKeys[0]];
      const fieldInParamNotNull =
        fieldInParam && valueInParam && fieldInParam.nullState !== valueInParam;

      if (field.urlParam && !fieldInParamNotNull) {
        if (payload || field.nullState) {
          // Если поле - массив и заполнено больше 1 элемента
          // добавляем в query
          if (field.array && payload.length > 1) {
            delete route.params[field.name];
            route.query[field.name] = payload;

            return;
          }

          // Если поле - массив и заполнен только один элемент
          // Добавляем в params первый элемент массива, убираем из query
          if (field.array && payload.length === 1) {
            route.params = { [field.name]: payload[0] };
            route.name = field.routeName;
            delete route.query[field.name];
            return;
          }

          if (field.array && !payload.length) {
            // Если поле - массив, но он пустой
            // Добавляем в params nullState, убираем из query
            route.params = { [field.name]: field.nullState || undefined };
            route.name = field.routeName;
            delete route.query[field.name];
            return;
          }

          // Если поле - не массив
          // Добавляем в params, убираем из query
          route.params = { [field.name]: payload || field.nullState };
          route.name = field.routeName;
          delete route.query[field.name];
          return;
        }
      }

      // Добавляем в query
      if (field.urlQuery || fieldInParamNotNull) {
        if (payload) {
          route.query[field.name] = payload;
        } else {
          delete route.query[field.name];
        }
        delete route.params[field.name];
      }

      return;
    },
  },
  watch: {
    namespace: {
      handler(payload) {
        if (!payload) return;
        this.filterNamespace = payload;
      },
      immediate: true,
    },
  },
};
