import { render, staticRenderFns } from "./_courses.vue?vue&type=template&id=6fb74359&scoped=true&"
import script from "./_courses.vue?vue&type=script&lang=js&"
export * from "./_courses.vue?vue&type=script&lang=js&"
import style0 from "./_courses.vue?vue&type=style&index=0&lang=sass&"
import style1 from "./_courses.vue?vue&type=style&index=1&id=6fb74359&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fb74359",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {D0822Header: require('/app/landing_generator/components/d0822/header.vue').default,CoursesIntro: require('/app/landing_generator/components/courses/coursesIntro.vue').default,CoursesFilter: require('/app/landing_generator/components/courses/coursesFilter.vue').default,D0822Form: require('/app/landing_generator/components/d0822/form.vue').default,D0822Footer: require('/app/landing_generator/components/d0822/footer.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})
